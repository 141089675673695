<template>
  <div>
    <!-- reload dialog -->
    <v-dialog
      persistent
      v-model="reloadDialog"
      max-width="300px"
    >
      <v-card class="card-dialog">
        <app-header
          title="Assessment Scoring"
          :is-dialog="true"
          @closeDialog="closeReloadDialog()"
        />
        <v-container
          fluid
          class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
        >
          <v-row>
            <p>
              Your results for Part 1 have not finished scoring. This needs to be completed before you can proceed to
              Part 2. Please wait 30 seconds and press the 'Try Again' button below.
            </p>
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="getPartialScore(studentAssessment)"
            >
              Try Again
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- next exam dialog -->
    <v-dialog
      v-model="nextExamDialog"
      max-width="500px"
    >
      <v-card class="card-dialog">
        <app-header
          title="Next Assessment"
          :is-dialog="true"
          @closeDialog="closeReloadDialog()"
        />
        <v-container
          fluid
          class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
        >
          <v-row>
            <p>{{ nextExamMessage }}</p>
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="exitView"
            >
              Okay
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- overlay -->
    <v-overlay
      v-if="processingAssessment"
      z-index="10000"
    >
      We are processing your assessment, please wait...
    </v-overlay>

    <h3 v-if="studentAssessment != null">
      {{ studentAssessment.assessment.set }} {{ studentAssessment.assessment.section }}
    </h3>

    <div id="learnosityContainer">
      <div
        id="learnosity_assess"
        :class="learnosityVisibility"
      />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

import {StudentAssessmentApi} from '@/api'
import {StudentAssessment} from '@/models'

import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')

export default {
  components: {
    AppHeader
  },

  beforeRouteLeave(to, from, next) {
    this.saveProgress()
    next()
  },

  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      studentAssessment: null,
      processingAssessment: false,
      learnosityVisible: true,
      reloadDialog: false,
      nextExamDialog: false,
      nextExamMessage: '',
      nextExamInitialized: null,
      itemsApp: null
    }
  },

  computed: {
    learnosityVisibility() {
      return {
        visibility: this.learnosityVisible ? 'hidden' : 'visible'
      }
    }
  },

  async mounted() {
    this.studentAssessment = await this.loadAssessment(this.$route.params.id)

    if (this.user().untimedAssessment || this.studentAssessment.settings.readspeakerEnabled) {
      const readSpeakerScript = document.createElement('script');
      readSpeakerScript.src = '//cdn-na.readspeaker.com/script/13323/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js';
      document.head.appendChild(readSpeakerScript);
    }

    const itemsScript = document.createElement('script');
    itemsScript.src = '//items.learnosity.com?v2024.2.LTS';
    itemsScript.addEventListener('load', () => {
      this.startAssessment(this.studentAssessment)
    });
    document.head.appendChild(itemsScript);
  },

  methods: {
    ...mapGetters(["user"]),

    async loadAssessment(id) {
      try {
        const response = await StudentAssessmentApi.get(id)
        
        if (response.studentAssessment.completed && response.studentAssessment.hasNextPart) {
          if (response.studentAssessment.nextPartId === -1) {
            this.nextExamInitialized = false
          } else {
            this.nextExamInitialized = true
          }
        }

        return new StudentAssessment(response.studentAssessment)
      } catch (e) {
        this.addNotification('error', "Could not load exam.")
      }
    },

    async startAssessment(assessment) {
      const id = assessment.id;

      this.learnosityVisible = true

      StudentAssessmentApi.initialize_test(id)
          .then(response => {

            let eventOptions = {}
            if (this.user().untimedAssessment || this.studentAssessment.settings.readspeakerEnabled) {
              eventOptions = {
                readyListener: () => {
                  console.log('Items API has successfully initialized.');

                  window.rsCallbacks.readyListener();
                },
                errorListener: (err) => {
                  console.log(err);
                },
              };
            }

            const itemsApp = window.LearnosityItems.init(response, eventOptions);
            this.itemsApp = itemsApp

            itemsApp.on("test:start", async () => {
              await StudentAssessmentApi.learnosity_callback(assessment.id, "test:start")

              if (assessment.assessment.category.name === 'Math') {
                itemsApp.questionsApp().renderMath()
                let assessApp = itemsApp.assessApp();

                assessApp.append({
                  features: [{
                    "type": "customfeature",
                    "js": "https://assets.learnosity.com/partners/desmos/customtypes/feature-calculator/desmos.js",
                    "css": "https://questions-va.learnosity.com/latest/customassets/redirect?customType=calculator-feature&assetType=css&clientReference=988e92a0",
                    "width": "640",
                    "height": "420",
                    "lrn_theme": false
                  }]
                }).then(function (data) {
                  let graphingCalculator = data.features['customfeature-0'];

                  // dispatch the public method that is defined inside graphing calculator customfeature
                  assessApp
                      .on('button:desmos_calculator:clicked', function () {
                        graphingCalculator.toggle();
                      })
                      .on('test:pause', function () {
                        if (graphingCalculator !== undefined) {
                          graphingCalculator.stop();
                        }
                      })
                      .on('item:warningOnChange', function () {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                      .on('test:finished:submit', () => {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                      .on('test:panel:show', function () {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                      .on('test:save', function () {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                      .on('test:save:error', function () {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                      .on('test:save:success', function () {
                        if (graphingCalculator !== undefined) {
                          setTimeout(() => {
                            graphingCalculator.stop();
                          }, 100)
                        }
                      })
                });
              }
            })

            itemsApp.on("test:submit:success", async () => {
              this.processingAssessment = true
              this.learnosityVisible = false

              if (assessment.hasNextPart) {
                await this.getPartialScore(assessment)
              } else {
                await StudentAssessmentApi.learnosity_callback(assessment.id, "test:submit:success")
                this.processingAssessment = false
                this.$router.push({name: 'StudentAssessments'});
              }
            })

          })
          .catch(error => {
              if (this.nextExamInitialized !== null) {
              this.addNotification('info', "You have completed this assessment. Please wait while we finish scoring before you proceed to Part 2.")

              setTimeout(() => {
                if (this.nextExamInitialized) {
                  this.getNextAssessment(this.studentAssessment)
                } else {
                  this.getPartialScore(this.studentAssessment)
                }
              }, 3000)
            } else {
              this.addNotification('error', error.response.data.error)
            }
          })
    },

    async getPartialScore(assessment) {
      if (!this.processingAssessment) {
        this.processingAssessment = true
      }

      this.reloadDialog = false
      try {
        const updatedAssessment = await StudentAssessmentApi.get_partial_score(assessment.id)
        if (updatedAssessment.studentAssessment.completed) {
          this.getNextAssessment(assessment)
        } else {
          this.reloadDialog = true
          this.processingAssessment = false
        }
      } catch (error) {
        this.reloadDialog = true
        this.processingAssessment = false
      }
    },

    closeReloadDialog() {
      this.reloadDialog = false
    },

    exitView() {
      this.$router.push({name: 'StudentAssessments'});
    },

    async getNextAssessment(assessment) {
      try {
        const nextAssessment = await StudentAssessmentApi.get_next_assessment(assessment.id)
        this.nextExamInitialized = null
        if (nextAssessment != null) {
          this.studentAssessment = new StudentAssessment(nextAssessment.studentAssessment)
          if (this.studentAssessment.status != "upcoming") {
            this.startAssessment(this.studentAssessment)
          } else {
            this.nextExamMessage = `The next exam will be available on ${this.studentAssessment.earliestAvailability()}.`
            this.nextExamDialog = true
          }
          this.processingAssessment = false
        } else {
          setTimeout(() => {
            this.getNextAssessment(this.studentAssessment)
          }, 10000)
        }
      } catch (error) {
        setTimeout(() => {
          this.getNextAssessment(this.studentAssessment)
        }, 10000)
      }
    },

    async saveProgress() {
      if (this.itemsApp != null) {
        this.itemsApp.save();
      }
    },

    async retryPartialScore() {
      await this.getPartialScore(this.studentAssessment)
      this.nextExamInitialized = null
    }
  }
}
</script>
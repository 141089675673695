<template>
  <v-container fluid>
    <app-header title="Exports" />
    <v-form
      ref="exportForm"
      class="form-default"
    >
      <!--School Selection-->
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="schools"
            >
              Which Schools?
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-autocomplete
            id="schools"
            v-model="exportForm.school_ids"
            label="Select School"
            :menu-props="{ maxHeight: '200' }"
            class="input-default input-default__height-unset input-default__min-height-default mt-1"
            dense
            multiple
            solo
            flat
            clearable
            required
            item-value="id"
            item-text="name"
            :rules="exportFormRules.schools"
            :items="schoolsArray"
            hide-details="auto"
            :loading="loadingSchools"
          />
        </v-col>
      </v-row>

      <!--Assessment Selection-->
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="assessments"
            >
              Which Assessments?
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-autocomplete
            id="assessments"
            v-model="selectedAssessments"
            label="Select Assessment"
            :menu-props="{ maxHeight: '200' }"
            dense
            multiple
            solo
            flat
            class="input-default input-default__height-unset input-default__min-height-default mt-1"
            clearable
            item-text="name"
            :rules="exportFormRules.assessments"
            :items="assessmentsArray"
            return-object
            hide-details="auto"
            required
            :loading="loadingAssessments"
          />
        </v-col>
      </v-row>

      <!--Assessment Selection-->
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="schoolYears"
            >
              School Year
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-autocomplete
            id="schoolYears"
            v-model="selectedSchoolYear"
            label="Select School Year"
            :menu-props="{ maxHeight: '200' }"
            dense
            solo
            flat
            class="input-default input-default__height-unset input-default__min-height-default mt-1"
            clearable
            item-text="name"
            :rules="exportFormRules.schoolYear"
            :items="schoolYearsArray"
            return-object
            hide-details="auto"
            required
            :loading="loadingSchoolYears"
          />
        </v-col>
      </v-row>

      <!--Options Selection-->
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
            >
              Which Reports?
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          class="pt-1 pt-sm-3 d-flex flex-wrap flex-grow-1"
        >
          <v-switch
            v-model="exportForm.options"
            value="score"
            label="Score Export"
            color="#496e88"
            class="switch-form ml-1 mr-6 pt-1"
            inset
            hide-details
          />
          <v-switch
            v-model="exportForm.options"
            value="response"
            label="Response Export"
            color="#496e88"
            class="switch-form ml-1 mt-1 mt-sm-0"
            inset
            hide-details
          />
        </v-col>
      </v-row>

      <!--File Options-->
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
            >
              File Options
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          class="pt-1 pt-sm-3 d-flex flex-wrap flex-grow-1"
        >
          <v-switch
            v-model="exportForm.file_option"
            value="single"
            label="One File"
            color="#496e88"
            class="switch-form ml-1 mr-6 pt-1"
            inset
            hide-details
          />
          <v-switch
            v-model="exportForm.file_option"
            value="multi"
            label="Separate Files Per School, Assessment Type"
            color="#496e88"
            class="switch-form ml-1 mt-1 mt-sm-0"
            inset
            hide-details
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row
      dense
      justify="end"
      class="mt-6"
    >
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          dark
          :loading="generatingExport"
          class="btn-default btn-default__primary"
          block
          @click="onGenerateReport()"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>

    <!--Exports table-->
    <app-header
      title="Downloads"
    />

    <v-data-table
      :height="getTableHeight()"
      item-key="id"
      :options.sync="tableOptions"
      :server-items-length="totalExports"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="reports"
      class="table-default table-radius-top-none table-default__header-fixed"
      :loading="tableLoading ? '#3d5d74' : false"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[itemsPerPage],
        disableItemsPerPage: true
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.assessmentGroups`]="{ item }"
      >
        <td>
          <span
            v-for="(assessmentName, index) in item.assessmentGroups"
            :key="index"
          >
            {{ assessmentName.toUpperCase() }}{{ index !== item.assessmentGroups.length - 1 ? ',' : '' }}
          </span>
        </td>
      </template>


      <template
        #[`item.processType`]="{ item }"
      >
        {{ formatType(item.processType) }}
      </template>

      <template
        #[`item.totalSchools`]="{ item }"
      >
        {{ item.totalSchools }} Schools
      </template>

      <template
        #[`item.createdAt`]="{ item }"
      >
        {{ formattedDate(item.createdAt) }}
      </template>

      <template
        #[`item.status`]="{ item }"
      >
        {{ formatStatus(item.status) }}
      </template>

      <template
        #[`item.actions`]="{ item }"
      >
        <td class="text-center">
          <v-btn
            v-if="item.status === 'delivered'"
            class="btn-table btn-table__text"
            outlined
            width="100"
            :loading="generatingExport"
            @click="onDownloadReport(item)"
          >
            Download
          </v-btn>
          <v-btn
            v-if="item.status === 'failed'"
            class="btn-table btn-table__text"
            width="100"
            outlined
            :loading="generatingExport"
            @click="onRetryReport(item)"
          >
            Retry
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex"

const {mapGetters: mapAssessmentGetters, mapActions: mapAssessmentActions} = createNamespacedHelpers('assessments')
import AppHeader from "@/components/shared/AppHeader.vue";
import {
  DistrictAdminAssessmentsApi,
  DistrictAdminExportsApi,
  DistrictAdminSchoolsApi,
  DistrictAdminSchoolYearApi,
} from "@/api";
import moment from "moment/moment";


export default {
  name: "ExportGenerate",
  components: {AppHeader},

  data() {
    return {
      generatingExport: false,
      loadingSchools: false,
      loadingAssessments: false,
      loadingSchoolYears: false,
      schoolsArray: [],
      assessmentsArray: [],
      schoolYearsArray: [],
      selectedAssessments: [],
      selectedSchoolYear: null,
      exportForm: {
        assessments: [],
        school_ids: [],
        options: ['score'],
        file_option : 'single'
      },
      exportFormRules: {
        schools: [
          v => v.length > 0 || 'Selecting at least one school is required'
        ],
        assessments: [
          v => v.length > 0 || 'Selecting at least one assessment is required'
        ],
        schoolYear: [
          v => !!v || 'Please select a school year'
        ]
      },

      // Table Data
      reports: [],
      tableLoading: false,
      tableLoadedOnce: false,
      totalExports: 0,
      tableOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      itemsPerPage: 100,
      tableHeight: 'calc(90vh - 315px)',
      headers: [
        {
          text: 'Schools',
          value: 'totalSchools',
          align: 'left',
          sortable: false,
          width: 130
        },
        {
          text: 'Assessment',
          value: 'assessmentGroups',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'processType',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Requested On',
          value: 'createdAt',
          align: 'center',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 120,
        }
      ]

    }
  },

  watch: {
    tableOptions: {
      handler() {
        if (this.tableLoadedOnce) {
          this.onLoadExports()
        }
      },
      deep: true,
    },

    'exportForm.options'(newVal, oldVal) {
      if (newVal.length) {
        this.exportForm.options = newVal
      } else {
        setTimeout(() => this.exportForm.options = oldVal)
      }
    },

    'exportForm.file_option'(newVal, oldVal) {
      if (newVal) {
        this.exportForm.file_option = newVal
      } else {
        setTimeout(() => this.exportForm.file_option = oldVal)
      }
    }
  },

  mounted() {
    this.loadSchools()
    this.loadAssessments()
    this.loadSchoolYears()
    this.onLoadExports()
  },

  methods: {
    ...mapAssessmentActions(['storeAssessmentData', 'resetAssessmentState']),
    ...mapAssessmentGetters(["getAssessmentChoices", "getAvailableAssessmentCategories"]),

    async loadSchools() {
      try {
        this.loadingSchools = true
        const response = await DistrictAdminSchoolsApi.list()
        this.schoolsArray = response.schools
      } catch {
        this.addNotification('error', 'There was a problem loading schools')
      } finally {
        this.loadingSchools = false
      }
    },

    async loadAssessments() {
      try {
        this.loadingAssessments = true
        this.resetAssessmentState()
        const assessmentData = await DistrictAdminAssessmentsApi.getSupportedAssessments()
        this.storeAssessmentData(assessmentData)
        this.assessmentsArray = this.getAssessmentChoices()
      } catch {
        this.addNotification('error', 'There was a problem loading assessments')
      } finally {
        this.loadingAssessments = false
      }
    },

    async loadSchoolYears() {
      try {
        this.loadingSchoolYears = true
        const schoolYearData = await DistrictAdminSchoolYearApi.getSchoolYears()
        this.schoolYearsArray = schoolYearData.schoolYears
      } catch {
        this.addNotification('error', 'There was a problem loading school years')
      } finally {
        this.loadingSchoolYears = false
      }
    },

    async onGenerateReport() {
      try {
        if (!this.$refs.exportForm.validate()) return
        this.generatingExport = true
        const exportObjectTemp = {
          assessments: [],
          school_ids: this.exportForm.school_ids,
          options: '',
          file_option: this.exportForm.file_option,
          school_year_id: this.selectedSchoolYear.id
        }
        exportObjectTemp.assessments = this.selectedAssessments.map((el) => {
          return {assessment_set_id: el.assessmentSet?.id, assesment_type_id: el.assessmentType?.id}
        })

        if (this.exportForm.options.length > 1) {
          exportObjectTemp.options = 'both'
        } else {
          exportObjectTemp.options = this.exportForm.options[0]
        }

        await DistrictAdminExportsApi.generateExports(exportObjectTemp)
        this.onLoadExports()
        this.onResetForm()
        this.addNotification('info', 'The export is currently being generated. You can view progress and download the file from the ‘Downloads Table’ once complete', 8000)
      } catch {
        this.addNotification('error', 'Failed to generate export.')
      } finally {
        this.generatingExport = false
      }
    },

    async onLoadExports() {
      try {
        this.reports = []
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        const response = await DistrictAdminExportsApi.listExports(page, this.itemsPerPage, sortBy[0], order)
        this.totalExports = response.meta?.totalCount
        this.reports = response.batchProcessJobs

      } catch {
        this.addNotification('error', 'There was a problem getting exports')
      } finally {
        this.tableLoadedOnce = true
        this.tableLoading = false
      }
    },

    async onDownloadReport(item) {
      try {
        const response = await DistrictAdminExportsApi.downloadExport(item.id)
        window.open(response.download_url, "_blank");
      } catch {
        this.addNotification('error', 'There was a problem downloading this report')
      }
    },

    async onRetryReport(item) {
      try {
        this.generatingReport = true
        await DistrictAdminExportsApi.retryExport(item.id)
        this.tableLoading = true
        setTimeout(() => {
          this.onLoadExports()
        }, 1000)
      } catch {
        this.addNotification('error', 'Failed to retry generating the export')
      } finally {
        this.generatingReport = false
      }
    },

    formatType(type) {
      if (type === 'BATCHEXCEL_RESPONSE') {
        return 'Response'
      }

      if (type === 'BATCHEXCEL_SCORE') {
        return 'Score Report'
      }

      return 'Response, Score Report'
    },

    formatStatus(status) {
      if (status === 'delivered') {
        return 'Ready'
      }

      if (status === 'failed') {
        return 'Failed'
      }

      return 'Processing'
    },

    formattedDate(date) {
      return moment(date).format('lll')
    },

    onResetForm() {
      this.exportForm = {
        assessments: [],
        school_ids: [],
        options: ['score'],
        file_option : 'single'
      }
      this.selectedAssessments = []
      this.$refs.exportForm.resetValidation()
    },

    getTableHeight () {
      if (this.reports.length < 6) return undefined

      if(this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 700) return '400px'

      return this.tableHeight
    },

  }
}
</script>

<template>
  <div>
    <app-tabs-secondary
      :tabs="tabs"
      @onTabChange="onLoadCategory(...arguments)"
    />
    <v-container fluid>
      <v-row
        align="center"
        dense
      >
        <v-col
          cols="12"
          sm="auto"
          class="mr-sm-4"
        >
          <h3>
            {{ showActiveUsers ? 'Active' : 'Archived' }} {{ tabTypePlural }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            v-if="showActiveUsers"
            class="btn-default btn-default__secondary "
            :title="`Open Archived ${tabTypePlural}`"
            block
            @click="onStudentStatusChange(false)"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/arrows-swap.png')"
              alt="icon-swap"
              max-width="15"
              max-height="15"
            />
            <span>
              Archived {{ tabTypePlural }}
            </span>
          </v-btn>
          <v-btn
            v-else
            class="btn-default btn-default__secondary"
            block
            :title="`Open Active ${tabTypePlural}`"
            @click="onStudentStatusChange(true)"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/arrows-swap.png')"
              alt="icon-swap"
              max-width="15"
              max-height="15"
            />
            <span>
              Active {{ tabTypePlural }}
            </span>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            v-if="tabRoleIsStudent()"
            dark
            class="btn-default btn-default__secondary"
            block
            @click="rosterExport()"
          >
            <v-icon
              class="mr-2"
              size="19"
              color="#344054"
            >
              mdi-file-document-arrow-right-outline
            </v-icon>
            Export List
          </v-btn>
        </v-col>
        <v-col
          v-if="tabRoleIsStudent()"
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="onUploadUsers('Parent')"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/importlist.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Import Parent List
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="onUploadUsers(tabRole)"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/importlist.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            <span v-if="tabRoleIsStudent()">
              Import Student List
            </span>
            <span v-else>
              Import User List
            </span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__primary"
            block
            @click="onAddDialog()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/addadmin.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Add {{ tabTypeSingular }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>
            <v-row
              dense
              class="table-header-extension"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="tableSearch"
                  placeholder="Search"
                  prepend-inner-icon="mdi-magnify"
                  class="input-default input-default__table"
                  clearable
                  hide-details
                  solo
                  flat
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="tabTypeSingular === 'Student'"
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onAddToClass()"
                >
                  Add to Class
                </v-btn>
              </v-col>
              <v-col
                v-if="tabTypeSingular === 'Student'"
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onSetTimed()"
                >
                  Set Timed
                </v-btn>
              </v-col>
              <v-col
                v-if="tabTypeSingular === 'Student'"
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onSetUntimed()"
                >
                  Set Untimed
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  v-if="showActiveUsers"
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onStudentStatusUpdate(true)"
                >
                  Archive Users
                </v-btn>
                <v-btn
                  v-else
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onStudentStatusUpdate(false)"
                >
                  Activate Users
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onDeleteMultipleUsers()"
                >
                  Delete Users
                </v-btn>
              </v-col>
              <v-col
                v-if="noSelectedUsers"
                cols="12"
              >
                <h6 class="color-danger text-right">
                  Please select at least one user!
                </h6>
              </v-col>
            </v-row>

            <v-data-table
              v-model="selectedUsers"
              :height="getTableHeight()"
              show-select
              item-key="id"
              :server-items-length="totalUsers"
              :options.sync="tableOptions"
              :headers="headers"
              :headers-length="headers.length + 1"
              :items="usersArray"
              :loading="tableLoading ? '#3d5d74' : false"
              class="table-default table-default__select table-radius-top-none table-default__header-fixed"
              fixed-header
              :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions:[itemsPerPage],
                disableItemsPerPage: true
              }"
              @toggle-select-all="selectAllToggle"
            >
              <template #[`footer.page-text`]="items">
                <span class="font-weight-700">{{ items.pageStart }}</span> to
                <span class="font-weight-700">{{ items.pageStop }}</span> of
                <span class="font-weight-700">{{ items.itemsLength }}</span>
              </template>

              <template
                v-if="!isMobile"
                #[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  v-if="!item.hiddenAccount"
                  :value="isSelected"
                  :readonly="item.hiddenAccount"
                  :disabled="item.hiddenAccount"
                  @input="select($event)"
                />
              </template>

              <template
                v-for="header in headers"
                #[`header.${header.value}`]
              >
                <span
                  :key="header.text"
                  :aria-label="`Header: ${header.text}`"
                  tabindex="0"
                >
                  {{ header.text }}
                </span>
              </template>

              <template
                v-if="!isMobile"
                #[`header.gradeLevel`]="{ header }"
              >
                <div class="d-flex align-center justify-space-between">
                  <span
                    class="mr-3"
                    :aria-label="`Header: ${header.text}`"
                    tabindex="0"
                  >
                    {{ header.text }}
                  </span>
                  <app-table-filter
                    :filter-options="gradeLevelList"
                    :column="header?.value"
                    :loading="tableLoading"
                    @onFilterChange="onFilterChange"
                  />
                </div>
              </template>

              <template
                v-if="!isMobile"
                #[`item.createdAt`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.createdAt) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.lastLoginAt`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.lastLoginAt) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.firstName`]="{ item }"
              >
                <td>
                  <v-tooltip
                    top
                    color="#38566b"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-if="isHorizonAdmin()"
                        size="20"
                        color="#38566b"
                        class="mr-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <div>
                      <span class="font-size-12">User ID: {{ item.id }}</span>
                    </div>
                  </v-tooltip>
                  <span class="mr-3">{{ item.firstName }}</span>
                  <span
                    v-if="item.untimedAssessment"
                    class="untimed-container"
                  >
                    Untimed
                  </span>
                </td>
              </template>
              <template
                v-if="!isMobile"
                #[`item.email`]="{ item }"
              >
                <td>
                  <span v-if="!item.hiddenAccount">{{ item.email }}</span>
                </td>
              </template>
              <template
                v-if="!isMobile"
                #[`item.actions`]="{ item }"
              >
                <td>
                  <v-row dense>
                    <v-col
                      class="d-flex"
                      :class="item.hiddenAccount ? 'justify-start': 'justify-end'"
                    >
                      <v-btn
                        v-if="canMasquerade()"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Impersonate user"
                        @click="onConfirmMasqueradeDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-swap-horizontal
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="tabRole === 'Student' && userRole() !== 'Horizon Admin'"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Students profile modal"
                        @click="onStudentProfileDialog(item)"
                      >
                        <v-img
                          contain
                          :src="require('@/assets/icons/profile-primary.png')"
                          alt="icon-edit"
                          max-width="15"
                          max-height="15"
                        />
                      </v-btn>
                      <v-btn
                        v-if="!item.hiddenAccount && (tabRole !== 'Student' || userRole() === 'Horizon Admin')"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Edit user"
                        @click="onEditDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!item.hiddenAccount"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Delete user"
                        @click="onDeleteDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template
                v-if="isMobile"
                #item="{ item, isSelected, select }"
              >
                <tr>
                  <td>
                    <ul class="flex-content">
                      <li
                        class="flex-item"
                        data-label="Selected"
                      >
                        <v-simple-checkbox
                          :value="isSelected"
                          :readonly="item.hiddenAccount"
                          :disabled="item.hiddenAccount"
                          @input="select(!isSelected)"
                        />
                      </li>
                      <li
                        class="flex-item"
                        data-label="First Name"
                      >
                        <v-tooltip
                          top
                          color="#38566b"
                        >
                          <template #activator="{ on, attrs }">
                            <v-icon
                              v-if="isHorizonAdmin()"
                              size="20"
                              color="#38566b"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <div>
                            <span class="font-size-12">User ID: {{ item.id }}</span>
                          </div>
                        </v-tooltip>
                        {{ item.firstName }}
                        <span
                          v-if="item.untimedAssessment"
                          class="untimed-container"
                        >Untimed</span>
                      </li>
                      <li
                        class="flex-item"
                        data-label="Last Name"
                      >
                        {{ item.lastName }}
                      </li>

                      <li
                        v-if="tabRole === 'Student'"
                        class="flex-item"
                        data-label="Grade"
                      >
                        {{ item.gradeLevel }}
                      </li>
                      <li
                        v-else
                        class="flex-item"
                        data-label="Email"
                      >
                        <span v-if="!item.hiddenAccount">{{ item.email }}</span>
                      </li>
                      <li
                        class="flex-item"
                        data-label="Date Added"
                      >
                        {{ formatDateTable(item.createdAt) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Last Active"
                      >
                        {{ formatDateTable(item.lastLoginAt) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="# of Logins"
                      >
                        {{ item.nrOfLogins }}
                      </li>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-btn
                          v-if="canMasquerade()"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Impersonate user"
                          @click="onConfirmMasqueradeDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-swap-horizontal
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="tabRole === 'Student' && userRole() !== 'Horizon Admin'"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Students profile modal"
                          @click="onStudentProfileDialog(item)"
                        >
                          <v-img
                            contain
                            :src="require('@/assets/icons/profile-primary.png')"
                            alt="icon-edit"
                            max-width="15"
                            max-height="15"
                          />
                        </v-btn>
                        <v-btn
                          v-if="!item.hiddenAccount && (tabRole !== 'Student' || userRole() === 'Horizon Admin')"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Edit User"
                          @click="onEditDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-square-edit-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!item.hiddenAccount"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Delete user"
                          @click="onDeleteDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </ul>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-student-profile
      :active="dialogStudentProfile"
      :student-prop="userProp"
      :school-id="id"
      @closeDialog="onCloseDialogStudentProfile()"
      @closeDialogUpdate="onCloseDialogStudentProfile(true)"
    />

    <dialog-student-form
      :title="dialogTitle"
      :active="dialogStudent"
      :type="dialogType"
      :student-prop="userProp"
      :school-id="id"
      @closeDialog="onCloseDialogStudent()"
      @closeDialogUpdate="onCloseDialogStudent(true)"
    />

    <dialog-admin-form
      :title="dialogTitle"
      :active="dialogAdmin"
      :type="dialogType"
      :user-type="tabTypeSingular"
      :user-prop="userProp"
      :school-id="id"
      :role="tabRole"
      @closeDialog="onCloseDialogAdmin()"
      @closeDialogUpdate="onCloseDialogAdmin(true)"
    />

    <dialog-upload-users
      :title="`Upload ${importRole} List`"
      :active="dialogUploadUsers"
      :school-id="id"
      :role="importRole"
      @updateTable="getUsers()"
      @closeDialog="onCloseDialogUploadUsers()"
      @closeDialogUpdate="onCloseDialogUploadUsers(true)"
    />

    <dialog-add-to-class
      :active="dialogAddToClass"
      :student-ids="studentIds"
      :school-id="id"
      @closeDialog="onCloseDialogAddToClass()"
      @closeDialogUpdate="onCloseDialogAddToClass(true)"
    />

    <dialog-action
      :active="dialogSetUntimed"
      :loading="untimedLoader"
      title-prop="Set Untimed Confirmation"
      body-text-prop="Do you want the selected users to have Untimed Assessments?"
      @closeDialog="onCloseDialogSetUntimed()"
      @confirmDialog="onSetUntimedConfirmation()"
    />

    <dialog-action
      :active="dialogSetTimed"
      :loading="timedLoader"
      title-prop="Set Timed Confirmation"
      body-text-prop="Do you want the selected users to have Timed Assessments?"
      @closeDialog="onCloseDialogSetTimed()"
      @confirmDialog="onSetTimedConfirmation()"
    />

    <dialog-action
      :active="dialogMasquerade"
      :loading="masqueradeLoader"
      :body-text-prop="`Do you want to login as ${userProp.fullName} (${userProp.role})`"
      @closeDialog="onCloseDialogMasquerade()"
      @confirmDialog="onConfirmMasquerade()"
    />

    <dialog-action
      :active="dialogStatusUpdate"
      :loading="dialogStatusLoader"
      title-prop="Update Status Confirmation"
      body-text-prop="Do you want to change the status of the selected user(s)?"
      @closeDialog="onCloseStudentStatusUpdate()"
      @confirmDialog="onStudentStatusUpdateConfirmation()"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveUser()"
    />

    <dialog-delete-confirmation
      :active="dialogBulkDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveMultipleUsers()"
    />
  </div>
</template>

<script>
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapActions, mapGetters} = createNamespacedHelpers('user')
import DialogStudentForm from "@/components/shared/DialogStudentForm.vue";
import DialogAdminForm from "@/components/shared/DialogAdminForm.vue";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogUploadUsers from "@/components/shared/DialogUploadUsers.vue";
import DialogAddToClass from "@/components/shared/DialogAddToClass.vue";
import DialogAction from "@/components/shared/DialogAction.vue";
import {AdminSchoolApi, StaffSchoolApi, AdminUserApi, StaffUserApi} from "@/api";
import functions from "@/api/shared/functions";
import debounce from 'lodash/debounce'
import moment from "moment";
import DialogStudentProfile from "@/components/shared/DialogStudentProfile.vue";
import AppTableFilter from "@/components/shared/AppTableFilter.vue";


export default {
  name: "AdminUsers",
  components: {
    AppTableFilter,
    DialogStudentProfile,
    DialogAction,
    DialogAddToClass,
    DialogUploadUsers, DialogDeleteConfirmation, DialogAdminForm, DialogStudentForm, AppTabsSecondary
  },

  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      pageLoadedOnce: false,
      tableLoading: false,
      onTableSearch: false,
      noSelectedUsers: false,
      dialogDeleteConfirmation: false,
      dialogBulkDeleteConfirmation: false,
      deletionLoader: false,
      dialogAdmin: false,
      dialogType: 'new',
      dialogStudent: false,
      importRole: '',
      dialogUploadUsers: false,
      dialogAddToClass: false,
      dialogStudentProfile: false,
      studentIds: [],
      dialogSetUntimed: false,
      dialogSetTimed: false,
      untimedLoader: false,
      timedLoader: false,
      dialogMasquerade: false,
      masqueradeLoader: false,
      dialogTitle: 'Add Student',
      dialogUploadTitle: 'Upload Student List',
      userProp: {},
      tabSelected: 0,
      selectedUsers: [],
      totalUsers: 0,
      disabledCount: 0,
      showActiveUsers: true,
      dialogStatusUpdate: false,
      dialogStatusLoader: false,
      statusChangeToArchive: true,
      tableOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      itemsPerPage: 100,
      tableHeight: 'calc(90vh - 544px)',
      tableSearch: '',
      tabs: [
        {
          tabText: 'Students',
          text: 'Student'
        },
        {
          tabText: 'Teachers',
          text: 'Teacher'
        },
        {
          tabText: 'Administrators',
          text: 'Administrator'
        },
      ],
      usersArray: [],
      roleList: ["Student", "Teacher", "Teacher Admin"],
      headers: [],
      tableActiveFilters: {},
      gradeLevelList: [8, 9, 10, 11, 12]
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    tabTypePlural() {
      if (this.tabSelected === 2) {
        return `School ${this.tabs[this.tabSelected].tabText}`
      }

      return this.tabs[this.tabSelected].tabText
    },

    tabTypeSingular() {
      return this.tabs[this.tabSelected].text
    },

    tabRole() {
      return this.roleList[this.tabSelected]
    },

  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.getUsers()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.getUsers()
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapGetters(["user", "userMasqIdent", "userMasqId", 'userRole']),
    ...mapActions(['createUserSession']),

    onLoadCategory(tab) {
      this.tableActiveFilters = {}
      this.tableSearch = ''
      this.tabSelected = tab
      this.showActiveUsers = true
      this.setHeaders()
      this.getUsers()
      this.resetSelectedUsers()
    },

    selectAllToggle(props) {
      if (this.selectedUsers.length !== this.usersArray.length - this.disabledCount) {
        this.selectedUsers = [];
        props.items.forEach(item => {
          if (!item.hiddenAccount) {
            this.selectedUsers.push(item);
          }
        });
      } else this.selectedUsers = [];
    },

    canMasquerade() {
      return !this.userMasqIdent() && this.isAdmin()
    },

    isAdmin() {
      return this.userRole() === 'Horizon Admin' || this.userRole() === "Teacher Admin" || (this.userRole() === "District Admin" && Object.prototype.hasOwnProperty.call(this.user(), "school"))
    },

    isHorizonAdmin() {
      return this.userRole() === 'Horizon Admin'
    },

    setHeaders() {
      this.headers = [
        {
          text: 'First Name',
          value: 'firstName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Grade',
          value: 'gradeLevel',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Date Added',
          value: 'createdAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Active',
          value: 'lastLoginAt',
          align: 'left',
          sortable: true,
        },
        {
          text: '# of Logins',
          value: 'nrOfLogins',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ]

      if (this.tabRole !== 'Student') {
        this.headers[2] = {
          text: 'Email Address',
          value: 'email',
          align: 'left',
          sortable: true,
        }
      }

    },

    async getUsers() {
      try {
        this.disabledCount = 0
        const roleToLoad = this.tabRole
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }

        this.tableLoading = true
        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffSchoolApi : AdminSchoolApi

        const response = await api.getUsersByRole(this.id, roleToLoad, page, this.itemsPerPage, sortBy[0], order, searchText, this.tableActiveFilters, !this.showActiveUsers)
        this.totalUsers = response?.meta?.totalCount
        this.usersArray = response.users
        this.usersArray.map(item => {
          if (item.hiddenAccount) this.disabledCount += 1
        })
      } catch {
        this.addNotification('error', 'There was a problem getting users')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    onFilterChange(column, filterArray) {
      this.tableActiveFilters[column] = filterArray
      this.getUsers()
    },

    formatDateTable(date) {
      if (date === 'Never') return 'Never'
      return moment.utc(date).format('LL')
    },

    resetSelectedUsers() {
      this.selectedUsers = []
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    onAddDialog() {
      this.dialogTitle = `Add ${this.tabTypeSingular}`
      this.dialogType = 'new'
      if (this.tabTypeSingular === 'Student') {
        this.dialogStudent = true
      } else {
        this.dialogAdmin = true
      }
    },

    //  Student Profile methods
    onStudentProfileDialog(user) {
      this.userProp = functions.deepCopySync(user)
      this.dialogStudentProfile = true
    },

    onCloseDialogStudentProfile(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogStudentProfile = false
    },

    //  Form methods
    onEditDialog(user) {
      this.userProp = functions.deepCopySync(user)
      this.dialogTitle = `Edit ${this.tabTypeSingular}`
      this.dialogType = 'edit'
      if (this.tabTypeSingular === 'Student') {
        this.dialogStudent = true
      } else {
        this.dialogAdmin = true
      }
    },

    onCloseDialogStudent(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogStudent = false
    },

    //  Teacher methods
    onCloseDialogAdmin(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogAdmin = false
    },

    // Upload methods
    onUploadUsers(role) {
      this.importRole = role
      this.dialogUploadUsers = true
    },

    onCloseDialogUploadUsers(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogUploadUsers = false
    },

    // Class methods
    onAddToClass() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.studentIds = this.selectedUsers.map(obj => obj.id)
      this.dialogAddToClass = true
    },

    onCloseDialogAddToClass() {
      this.dialogAddToClass = false
    },

    // Untimed methods
    onSetUntimed() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogSetUntimed = true
    },

    async onSetUntimedConfirmation() {
      try {
        const idList = this.selectedUsers.map(obj => obj.id)
        this.untimedLoader = true

        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffUserApi : AdminUserApi

        await api.setUntimedBulk(idList)
        this.dialogSetUntimed = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Students untimed successfully')
      } catch {
        this.addNotification('error', 'Failed to untime the student. Please try again.')
      } finally {
        this.untimedLoader = false
      }
    },

    onCloseDialogSetUntimed() {
      this.dialogSetUntimed = false
    },

    // Timed methods
    onSetTimed() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogSetTimed = true
    },

    async onSetTimedConfirmation() {
      try {
        const idList = this.selectedUsers.map(obj => obj.id)
        this.timedLoader = true

        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffUserApi : AdminUserApi

        await api.setTimedBulk(idList)
        this.dialogSetTimed = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Students untimed successfully')
      } catch {
        this.addNotification('error', 'Failed to untime the student. Please try again.')
      } finally {
        this.timedLoader = false
      }
    },

    onCloseDialogSetTimed() {
      this.dialogSetTimed = false
    },

    //  Delete methods

    onDeleteDialog(userProp) {
      this.userProp = userProp
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    onDeleteMultipleUsers() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogBulkDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogBulkDeleteConfirmation = true
      })
    },

    async onRemoveUser() {
      try {
        this.deletionLoader = true
        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffUserApi : AdminUserApi

        await api.deleteUser(this.userProp.id)
        this.dialogDeleteConfirmation = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'User removed successfully')
      } catch (error) {
        this.addNotification('error', 'Failed to remove the user. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    async onRemoveMultipleUsers() {
      try {
        this.deletionLoader = true
        const idList = this.selectedUsers.map(obj => obj.id)
        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffUserApi : AdminUserApi

        await api.deleteUserBulk(idList)
        this.dialogBulkDeleteConfirmation = false
        this.getUsers()
        this.resetPage()
        this.resetSelectedUsers()
        this.addNotification('success', 'Users removed successfully')
      } catch (error) {
        this.addNotification('error', 'Failed to remove the selected users. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.dialogBulkDeleteConfirmation = false
    },

    //  Masquerade methods
    onConfirmMasqueradeDialog(user) {
      this.userProp = user
      this.dialogMasquerade = true
    },

    async onConfirmMasquerade() {
      try {
        this.masqueradeLoader = true
        let payload = {
          login: {
            user_id: this.userProp.id,
            email: this.userProp.email,
            password: this.userProp.email,
            masqid: this.userMasqId()
          }
        }
        await this.createUserSession(payload)

        if (this.userProp.role === "Student") {
          this.$router.push({name: "StudentCourses"})
        } else {
          this.$router.push({name: "StaffDashboard"})
        }
        this.addNotification('success', `You are logged in as ${this.userProp.fullName}(${this.userProp.role})`)
      } catch (error) {
        this.addNotification('error', `Failed to login as ${this.userProp.fullName}. Please try again.`)
      } finally {
        this.masqueradeLoader = false
      }
    },

    onCloseDialogMasquerade() {
      this.dialogMasquerade = false
    },

    noSelectedUsersDisplay() {
      this.noSelectedUsers = true
      setTimeout(() => {
        this.noSelectedUsers = false
      }, 5000)
    },

    tabRoleIsStudent() {
      return this.tabRole === "Student"
    },

    rosterExport() {
      const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffSchoolApi : AdminSchoolApi

      api.studentRosterExport({role: "Student", schoolId: this.id})
          .then(({filename, blob}) => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click();
          }, (responseError) => {
            this.addNotification('error', responseError)
          })
    },

    getTableHeight() {
      if (this.usersArray.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },

    // Student status methods
    onStudentStatusChange(status) {
      this.resetSelectedUsers()
      this.showActiveUsers = status
      this.getUsers()
    },

    onStudentStatusUpdate(status) {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.statusChangeToArchive = status
      this.noSelectedUsers = false
      this.dialogStatusUpdate = true
    },

    async onStudentStatusUpdateConfirmation() {
      try {
        const idList = this.selectedUsers.map(obj => obj.id)
        this.dialogStatusLoader = true

        if (this.statusChangeToArchive) {
          await AdminSchoolApi.archiveUsers(this.id, idList)
        } else {
          await AdminSchoolApi.unarchiveUsers(this.id, idList)
        }

        this.dialogStatusUpdate = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Users status changed successfully')
      } catch {
        this.addNotification('error', 'Failed to change the status of the users. Please try again.')
      } finally {
        this.dialogStatusLoader = false
      }
    },

    onCloseStudentStatusUpdate() {
      this.dialogStatusUpdate = false
    },

  },
}
</script>

<style scoped>
.v-tooltip__content {
  padding: 5px 10px;
  border-radius: 6px
}
</style>

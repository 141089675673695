import HorizonApi from '@/api/horizon_api'

const USERS_PATH = `/api/admin/users`

export default {

    listHorizonUsers() {
        return HorizonApi.get(USERS_PATH)
    },

    getTeachers(schoolId) {
        const roles = ['Teacher', 'Teacher Admin']
        const params = {
            roles: roles,
            school_id: schoolId
        }
        return HorizonApi.get(USERS_PATH, {params: params})
    },

    search(userParam) {
        return HorizonApi.get(`${USERS_PATH}/search`, {params: userParam})
    },

    addNewUser(userObj) {
        return HorizonApi.post(USERS_PATH, {user: userObj})
    },

    updateUser(userId, userObj) {
        return HorizonApi.patch(`${USERS_PATH}/${userId}`, {user: userObj})
    },

    deleteUser(userId) {
        return HorizonApi.delete(`${USERS_PATH}/${userId}`)
    },

    deleteUserBulk(userIdArray) {
        return HorizonApi.post(`${USERS_PATH}/bulk_delete`, {userIds: userIdArray})
    },

    setUntimedBulk(userIdArray) {
        return HorizonApi.post(`${USERS_PATH}/bulk_update`, {userIds: userIdArray, untimed_assessment: true})
    },

	setTimedBulk(userIdArray) {
		return HorizonApi.post(`${USERS_PATH}/bulk_update`, {userIds: userIdArray, untimed_assessment: false})
	},

    importStudents (sectionId, role, csvFile) {
        let fd = new FormData();

        fd.append("file", csvFile)
        fd.append("role", role)

        let headers = {
            "Content-Type": "multipart/form-data"
        }

        return HorizonApi.post(`${USERS_PATH}/${sectionId}/students/import`, fd, headers)
    },

    getTeachersMinimal(schoolId) {
        const params = {
            school_id: schoolId
        }
        return HorizonApi.get(`${USERS_PATH}/minimal_index`, {params: params})
    },

    getMaskables(role) {
        const params = {
            maskable_role: role
        }
        return HorizonApi.get(`${USERS_PATH}/maskables`, {params: params})
    },

    setMask(user_id, type) {
        return HorizonApi.post(`${USERS_PATH}/set_mask`, {user_id: user_id, type: type})
    },

    clearMask(user_id) {
        return HorizonApi.post(`${USERS_PATH}/clear_mask`, {user_id: user_id})
    },

}

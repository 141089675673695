<template>
  <div v-if="!dataReady && !fromGroupComparison">
    <v-skeleton-loader
      class="ma-5"
      type="card-avatar, article, article"
    />
  </div>

  <div v-else>
    <div v-if="!!data">
      <div class="container-reports">
        <div class="d-flex align-center ma-4">
          <v-icon
            size="20"
            color="#38566b"
            class="mr-1"
          >
            mdi-account-group
          </v-icon>
          <span class="font-size-16">
            Number of Students Assessed:
            <span class="font-weight-700 color-dark-orange">
              {{ data.report.categoryDetails.studentAssessedCount }} Students
            </span>
          </span>
        </div>
        <div
          class="d-flex subsection-details-wrapper"
          :class="fromGroupComparison? 'subsection-details-wrapper__comparison' : undefined"
        >
          <div class="donut-chart-wrapper">
            <div class="donut-chart">
              <donut-chart
                :chart-id="getChartId(data.report.categoryDetails?.summary?.category)"
                :chart-title="'Correct'"
                chart-title-font-size="28px"
                :chart-range="false"
                :display-percentage="true"
                :average-score="averageScore(data.report.categoryDetails?.summary)"
                :min-score="1"
                :max-score="100"
                :color="'#f4701f'"
              />
            </div>
            <div class="donut-chart-content">
              <div
                class="donut-detail"
              >
                <h1>{{ data.report.categoryDetails.summary.questionsTotal }}</h1>
                <span>Total Questions</span>
              </div>
              <div
                class="donut-detail"
              >
                <h1>{{ data.report.categoryDetails.summary.correctTotal }}</h1>
                <span>Correct Answers</span>
              </div>
              <div
                class="donut-detail"
              >
                <h1>{{ data.report.categoryDetails.summary.incorrectTotal }}</h1>
                <span>Incorrect Answers</span>
              </div>
              <div
                class="donut-detail"
              >
                <h1>{{ data.report.categoryDetails.summary.blankTotal }}</h1>
                <span>Blank Answers</span>
              </div>
            </div>
          </div>

          <category-bars-percentage
            :category-list-prop="data.report.categoryDetails.subsectionDetails"
          />
        </div>

        <horizon-multilevel-table
          :columns="subsectionColumns"
          :expanded-columns="subsectionExpandedDetailsColumns"
          :data="data.report.categoryDetails.subsectionDetails"
          :sub-table-key="'topicAreaDetails'"
          :small="true"
          :table-setup="subsectionTableSetup"
          :clickable="true"
          :row-expand-data="subsectionDetails"
          :loading-sublevel="loadingSublevel"
          class="web-only"
          @loadDetails="loadSubsectionQuestions"
          @rowClicked="openLearnosityPreview"
        />

        <div
          v-if="!fromGroupComparison"
          class="table-header"
        >
          <div class="table-title">
            Standard Item Analysis
          </div>

          <v-btn
            v-if="reportFilters.forDashboard != 'district'"
            class="btn-default btn-default__secondary btn-default__white"
            @click="loadLearnosityReport()"
          >
            View Question & Answer Analysis
            <img
              alt="right-arrow"
              class="ml-2"
              height="13"
              width="13"
              :src="require(`@/assets/icons/right-arrow.png`)"
            >
          </v-btn>
        </div>
        <horizon-table
          v-if="!fromGroupComparison"
          :columns="standardItemAnalysisColumns"
          :data="data.report.standardItemAnalysis"
          :small="true"
          :clickable="true"
          class="web-only"
          @row-clicked="openLearnosityPreview"
        />
      </div>

      <dialog-learnosity-preview
        :active="previewDialog"
        :title="previewTitle"
        :preview-id="previewId"
        @closeDialog="closeLearnosityPreview"
      />
    </div>

    <div v-else>
      <div class="container-reports ma-6">
        No data.
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from "@/components/shared/charts/DonutChart"
import CategoryBarsPercentage from '@/components/shared/charts/CategoryBarsPercentage'
import HorizonMultilevelTable from '@/components/shared/HorizonMultilevelTable'
import HorizonTable from '@/components/shared/HorizonTable'
import DialogLearnosityPreview from '@/components/shared/DialogLearnosityPreview'

import {DistrictAdminStudentResultsApi, StaffStudentResultsApi} from '@/api'

export default {
  components: {
    CategoryBarsPercentage,
    DonutChart,
    HorizonTable,
    HorizonMultilevelTable,
    DialogLearnosityPreview
  },

  props: {
    fromGroupComparison: Boolean,
    duplicatedChart: Boolean,
    data: {
      type: Object
    },
    reportFilters: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      dataReady: false,
      subsectionColumns: [
        {column: 'subsectionArea', display: 'Subsections', width: 40, title: true, secondaryDisplay: 'topicArea'},
        {column: 'questionsTotal', display: this.fromGroupComparison ? '# of ?s' : 'Number of Questions', width: 8},
        {column: 'correctPercent', display: 'Correct (%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'incorrectPercent', display: 'Incorrect (%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'blankPercent', display: 'Blank (%)', width: 15, formatter: 'decimalToPercent'},
      ],
      subsectionExpandedDetailsColumns: [
        {column: 'subsectionArea', display: 'Subsections', width: 40, title: true, secondaryDisplay: 'question'},
        {column: 'questionsTotal', display: this.fromGroupComparison ? '# of ?s' : 'Number of Questions', width: 8},
        {column: 'correctPercent', display: 'Correct (%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'incorrectPercent', display: 'Incorrect (%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'blankPercent', display: 'Blank (%)', width: 15, formatter: 'decimalToPercent'},
      ],
      subsectionTableSetup: {
        headerColumns: true,
        header: true
      },
      standardItemAnalysisColumns: [],
      previewTitle: '',
      previewDialog: false,
      previewId: -1,
      subsectionDetails: [],
      loadingSublevel: false,
    }
  },


  watch: {
    data: {
      handler: function (value) {
        if (value !== undefined) {
          if (value == null) {
            this.dataReady = true
          } else {
            this.dataReady = true
            this.setupStandardItemAnalysisColumns()
          }
        }
      },
      deep: true
    }
  },

  methods: {
    averageScore(sectionDetails) {
      if (sectionDetails == null) {
        return ""
      } else {
        return (((sectionDetails.correctTotal / sectionDetails.questionsTotal) * 100).toFixed(1))
      }
    },

    getChartId(name) {
      const chartId = `chart-${name.replace('(', '').replace(')', '').replaceAll(' ', '-')}`

      if (this.duplicatedChart) return chartId + '-second'
      return chartId
    },

    setupStandardItemAnalysisColumns() {
      this.standardItemAnalysisColumns = [
        {column: 'question', display: "Question"},
        {column: 'subsectionAreaName', display: "Subsection"},
        {column: 'topicAreaName', display: "Area"},
        {column: 'averageTime', display: "Avg Time Spent"},
      ]

      // disregard questions that may have more choices than standard
      let answerCols = this.data.report.standardItemAnalysis.find(a => a.answers.length === 5 || a.answers.length === 6 || a.answers.length === 10 || a.answers.length === 11).answers

      for (let i = 0; i < answerCols.length; i++) {
        let option = answerCols[i].option
        this.standardItemAnalysisColumns.push({
          column: 'answers',
          display: option == "blank" ? "Blank" : `Response ${option}`,
          columnFn: (entry) => {
            // check if this is a multiple-choice entry or not
            let multipleChoice = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K"].includes(entry.answers.find(a => a.correct).option)

            let answer = entry.answers.find(a => a.option == option)
            if (answer.correct) {
              return `<span style="font-weight: 600; color: #f4701f;"><span class="pr-1">${answer.option}</span>(${answer.percent}%)</span>`
            } else {
              if (answer.option == "blank") {
                return `${answer.percent}%`
              } else {
                if (multipleChoice) {
                  return `${answer.option} (${answer.percent}%)`                  
                } else {
                  return "N/A"
                }
              }
            }
          }
        })
      }
    },

    loadLearnosityReport() {
      let pathName = "StaffResponseAnalysisByItem"
      if (this.$route.params.sectionId != null) {
        pathName = "StaffResponseAnalysisByItemWithSection"
      }
      const routeData = this.$router.resolve({
        name: pathName,
        params: {assessmentId: this.data.assessmentId},
        query: {
          year: this.reportFilters.schoolYear,
          sections: this.reportFilters.classes == null ? [] : this.reportFilters.classes,
          gradeLevels: this.reportFilters.gradeLevels
        }
      });
      window.open(routeData.href, '_blank');
    },

    openLearnosityPreview(item) {
      this.previewId = item.questionId
      this.previewTitle = item.question
      this.previewDialog = false
      this.$nextTick(() => {
        this.previewDialog = true
      })
    },

    closeLearnosityPreview() {
      this.previewDialog = false
    },

    async loadSubsectionQuestions(item) {
      if (!item.topicAreaId) {
        this.subsectionDetails = []
        return
      }

      try {
        this.loadingSublevel = true
        let response = null
        if (this.reportFilters.forDashboard == "district") {
          response = await DistrictAdminStudentResultsApi.getReportsSubsectionTopicAreaBreakdown({
            assessmentId: this.data.assessmentId,
            topicAreaId: item.topicAreaId,
            gradeLevels: this.reportFilters.gradeLevels,
            schoolYear: this.reportFilters.schoolYear,
            schoolIds: this.reportFilters.classes,
          })
        } else {
          response = await StaffStudentResultsApi.getReportsSubsectionTopicAreaBreakdown({
            assessmentId: this.data.assessmentId,
            topicAreaId: item.topicAreaId,
            gradeLevels: this.reportFilters.gradeLevels,
            schoolYear: this.reportFilters.schoolYear,
            sections: this.reportFilters.classes,
          })
        }

        this.subsectionDetails = response?.topicAreaQuestions
      } catch {
        this.addNotification('error', 'There was a problem loading questions')
      } finally {
        this.loadingSublevel = false
      }
    }

  }
}
</script>

<style scoped lang="scss">
// @import '~@styles/variables';

.container-reports {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  padding: 0;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
}

h2, .h2 {
  font-size: 18px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
}

h3, .h3 {
  font-size: 12px;
  margin-bottom: 0;
}

h4 {
  font-size: 10px;
  font-weight: 600;
  color: $subHeadingColor;
  margin-bottom: 0;
}

h5 {
  font-size: 10px;
  font-weight: 400;
  color: $subHeadingColor;
  margin-bottom: 0;
}

.btn-report {
  background-color: white;
  color: #345165;
  font-size: 12px;

  &:hover {
    background-color: #345165;
    color: white;
  }
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
  padding-bottom: 14px;
}

.tab {
  font-size: 18px;

  &.disabled {
    cursor: not-allowed;
  }
}

.subsection-details-wrapper {
  padding: 20px;

  &__comparison {
    flex-direction: column;
  }

}

.bar-chart-wrapper {
  padding-left: 15px;
  border-left: 1px solid #e8e8e8;

  .bar-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;

    .bar-row {
      display: flex;
      align-items: center;
      padding: 6px 0;

      > div {
        display: flex;
        justify-content: space-between;
      }

      :first-child {
        padding-right: 8px;
      }
    }

    .bar {
      height: 20px;
      background-color: $themeLtGray;
      width: 100%;
      position: relative;
      border-radius: 5px;

      &.completed {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    span {
      font-size: 12px;
    }

    .bar-specs {
      display: flex;
      justify-content: space-between;
      width: 30%;

      span:last-of-type {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}

.donut-chart-wrapper {
  padding: 10px 0;
  display: flex;
  align-items: center;

  .donut-chart-content {
    padding-left: 20px;
  }

  .donut-detail {
    display: flex;
    align-items: center;
    padding: 5px;

    h1 {
      margin-bottom: 3px;
      line-height: 32px;
      width: 30%;
      text-align: right;
      padding-right: 8px;
    }

    span {
      margin-left: 5px;
    }
  }
}

.table-header {
  background-color: #F3F3F3;
  height: 70px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  .table-title {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }

  .table-instruction {
    margin-left: 20px;
    color: #6b6b6b;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}

.web-only {
  display: block;
}

.w-tab-menu {
  display: none;
}

.subsection-details-wrapper {
  .donut-chart-wrapper {
    width: 45%;

    .donut-chart {
      width: 50%;
    }

    .donut-chart-content {
      width: 50%;
      padding-left: 0;
    }
  }

  .bar-chart-wrapper {
    width: 55%;
  }

  &__comparison {
    .donut-chart-wrapper {
      width: 100%;
    }

    .bar-chart-wrapper {
      width: 100%;
      padding-left: 0;
      border-left: none;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      width: 50%;
    }

    .bar-chart-wrapper {
      width: 50%;
    }

    &__comparison {
      .donut-chart-wrapper {
        width: 100%;
      }

      .bar-chart-wrapper {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    display: block !important;

    .donut-chart-wrapper {
      width: 100%;
    }

    .bar-chart-wrapper {
      width: 100%;
      padding-left: 0;
      border-left: 0;
      padding-top: 15px;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .web-only {
    display: none;
  }

  .report-title {
    h1 {
      font-size: 18px;
    }
  }

  .subsection-details-wrapper {
    padding-left: 8px;
    padding-right: 8px;

    .tabs-row {
      .tab {
        padding: 10px 13px;
        font-size: 14px;
      }
    }

    .donut-chart-wrapper {
      .donut-chart {
        width: 40%;
      }

      .donut-chart-content {
        width: 60%;
      }


      &__comparison {
        .donut-chart {
          width: 100%;
        }

        .donut-chart-content {
          width: 100%;
        }
      }

    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .tab {
    display: none;
  }

  .w-tab-menu {
    display: block;
    width: 100%;

    .w-tab-menu-item {
      width: 100%;
      transition: .2s;
      cursor: pointer;
      color: $mutedText;
      padding: 13px 25px;
      text-align: center;
      border-radius: 7px;

      &.selected {
        background-color: $primaryColor;
        color: #fff;
      }

      &:not(.selected):hover {
        color: $themeGray;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      display: block;

      .donut-chart {
        width: 100%;
      }

      .donut-chart-content {
        padding-top: 15px;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
// @import '~@styles/variables';

.subsection-loader-container {
  height: 60px;
  background: #F3F3F3;
  border-top: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;

  & > p {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    padding-left: 20px;
    margin: 0;
    line-height: 60px;
  }
}

.subsection-details-wrapper {
  .donut {
    .inner-donut-text {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 38px;
      }
    }
  }
}

@media screen and (max-width: 549px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: 329px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 42px;
      }
    }
  }
}
</style>

<template>
  <v-container
    fluid
    :class="hasLogo ? 'pt-1' : null"
  >
    <v-row>
      <v-col
        cols="12"
        class="header-container"
        :class="[isDialog ? ['dialog-header'] : undefined, isSecondary ? 'header-secondary' : undefined, fromDashboard ? 'from-dashboard' : null, hasButton ? 'pb-3' : null]"
      >
        <img
          v-if="hasLogo"
          :src="require('@/assets/img/School.png')"
          width="50"
          height="50"
          alt="school-img"
          class="mr-4"
        >

        <component 
          :class="hasLogo ? 'mt-2 mr-auto' : null"
          :is="titleComponent"
        >
          {{ title }}
          <slot name="detail"
            class="flex-shrink-1"
          >
          </slot>

          <v-btn
            v-if="canMasquerade()"
            class="btn-default btn-default__secondary ml-6"
            title="Impersonate Student"
            aria-label="Impersonate student"
            @click="onConfirmMasqueradeDialog()"
          >
            <v-img
              class="mr-sm-2"
              contain
              :src="require('@/assets/icons/arrows-swap.png')"
              alt="icon-swap"
              max-width="19"
              max-height="19"
            />
            <span class="d-none d-sm-inline">
              Impersonate Student
            </span>
          </v-btn>
        </component>

        <slot name="actionbar"
          class="ml-auto"
        ></slot> 

        <v-icon
          v-if="isDialog"
          class="icon-no-ripple"
          size="24"
          color="#3d5d74"
          aria-label="Close assessment modal"
          @click="onCloseDialog()"
        >
          mdi-close
        </v-icon>
<!-- 
        <div
          v-if="fromStudentProfile"
          class="d-flex align-center"
        >
          <h3>
            {{ title }}
          </h3>
          <v-btn
            v-if="canMasquerade()"
            class="btn-default btn-default__secondary ml-6"
            title="Impersonate Student"
            @click="onConfirmMasqueradeDialog()"
          >
            <v-img
              class="mr-sm-2"
              contain
              :src="require('@/assets/icons/arrows-swap.png')"
              alt="icon-swap"
              max-width="19"
              max-height="19"
            />
            <span class="d-none d-sm-inline">
              Impersonate Student
            </span>
          </v-btn>
        </div>
        <h4 v-else-if="isDialog">
          {{ title }}
        </h4>
        <h3 v-else-if="isSecondary">
          {{ title }}

          <v-chip
            v-if="!sectionEditable"
            class="section-chip-container"
          >
            <span class="section-chip-text font-weight-700">
              This class is created by Horizon, and automatically enrolls students based on grade level and accommodations status
            </span>
          </v-chip>
        </h3>

        <div
          v-else
          class="d-flex align-center"
        >
          <img
            v-if="hasLogo"
            :src="require('@/assets/img/School.png')"
            width="50"
            height="50"
            alt="school-img"
            class="mr-4"
          >
          <h1 :class="hasLogo ? 'mt-2' : null">
            {{ title }}
          </h1>
        </div>
        <v-icon
          v-if="isDialog"
          class="icon-no-ripple"
          size="24"
          color="#3d5d74"
          @click="onCloseDialog()"
        >
          mdi-close
        </v-icon>
        <v-spacer v-if="hasExportBtn || hasClassSelection" />
        <v-btn
          v-if="hasExportBtn"
          dark
          class="btn-default btn-default__primary btn-default__app-header"
          @click="goToDownloads()"
        >
          Export Downloads
        </v-btn>


        <slot name="actionbar"></slot> -->
      </v-col>
    </v-row>


    <dialog-action
      :active="dialogMasquerade"
      :loading="masqueradeLoader"
      :body-text-prop="`Do you want to login as ${userProp.fullName} (${userProp.role})`"
      @closeDialog="onCloseDialogMasquerade()"
      @confirmDialog="onConfirmMasquerade()"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapActions, mapGetters} = createNamespacedHelpers('user')
import DialogAction from "@/components/shared/DialogAction.vue";

export default {
  name: "AppHeader",
  components: {DialogAction},
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    isDialog: Boolean,
    isSecondary: Boolean,
    fromStudentProfile: Boolean,
    fromDashboard: Boolean,
    hasLogo: Boolean,
    hasButton: Boolean,
    userProp: {
      type: Object,
      default() {
        return {}
      },
    },
    teacherRole: {
      type: String,
      default: ''
    }
  },

  computed: {
    titleComponent() {
      if (this.isSecondary) {
        return "h3"
      } else if (this.isDialog) {
        return "h4"
      } else {
        return "h1"
      }
    }
  },

  data() {
    return {
      dialogMasquerade: false,
      masqueradeLoader: false,
    }
  },

  methods: {
    ...mapGetters(["userMasqIdent", "userMasqId", 'userRole']),
    ...mapActions(['createUserSession']),

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    // Masquerading methods
    canMasquerade() {
      return this.fromStudentProfile && !this.userMasqIdent() && (this.isAdmin() || this.userRole() === 'Teacher Admin' || this.teacherRole === "Primary Teacher")
    },

    isAdmin() {
      return this.userRole() === 'Horizon Admin' || this.userRole() === "Teacher Admin"
    },

    onConfirmMasqueradeDialog() {
      this.dialogMasquerade = true
    },

    async onConfirmMasquerade() {
      try {
        this.masqueradeLoader = true
        let payload = {
          login: {
            user_id: this.userProp.id,
            email: this.userProp.email,
            password: this.userProp.email,
            masqid: this.userMasqId()
          }
        }
        await this.createUserSession(payload)

        if (this.userProp.role === "Student") {
          this.$router.push({name: "StudentCourses"})
        } else {
          this.$router.push({name: "StaffDashboard"})
        }
        this.addNotification('success', `You are logged in as ${this.userProp.fullName}(${this.userProp.role})`)
      } catch (error) {
        this.addNotification('error', `Failed to login as ${this.userProp.fullName}. Please try again.`)
      } finally {
        this.masqueradeLoader = false
      }
    },

    onCloseDialogMasquerade() {
      this.dialogMasquerade = false
    },
  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  align-items: center;
  gap: 4px;
}

.header-secondary {
  padding-top: 4px;
  padding-bottom: 14px;
  margin-bottom: 20px;
}

.from-dashboard {
  border-bottom: none;
  margin-bottom: 0;
}
</style>

<template>
  <v-container
    v-if="reportsDataLoaded"
    fluid
  >
    <app-header
      title="Reports"
      :has-button="true"
    >
      <template #actionbar>
        <v-btn
          dark
          class="btn-default btn-default__secondary"
          @click="goToDownloads()"
        >
          <v-icon
            size="18"
            class="mr-2"
          >
            mdi-download
          </v-icon>
          Export Downloads
        </v-btn>
      </template>
    </app-header>
    <v-row class="mb-3">
      <v-col>
        <div
          class="reports-main-header"
          tabindex="0"
          aria-label="Group Reports"
        >
          <h3>Group Reports</h3>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col class="reports-card-container">
        <staff-reports-card
          v-for="report in reportsData.groupReports"
          :key="report.headerText"
          :link-prop="report.hrefLink"
          :header="report.headerText"
          :filename="report.filename"
          :footer="report.footerText"
          :aria-label="`${report.headerText}. Description: ${report.footerText}`"
        />
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <div
          class="reports-main-header"
          tabindex="0"
          aria-label="Individual Reports"
        >
          <h3>Individual Reports</h3>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col class="reports-card-container">
        <staff-reports-card
          v-for="report in reportsData.individualReports"
          :key="report.headerText"
          :link-prop="report.hrefLink"
          :header="report.headerText"
          :filename="report.filename"
          :footer="report.footerText"
          :aria-label="`${report.headerText}. Description: ${report.footerText}`"
        />
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col>
        <div
          class="reports-main-header"
          tabindex="0"
          aria-label="Exports"
        >
          <h3>Exports</h3>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="reports-card-container">
        <staff-reports-card
          v-for="report in reportsData.exports"
          :key="report.headerText"
          :link-prop="report.hrefLink"
          :header="report.headerText"
          :filename="report.filename"
          :footer="report.footerText"
          :aria-label="`${report.headerText}. Description: ${report.footerText}`"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const {mapGetters: mapGettersUser} = createNamespacedHelpers('user')

import AppHeader from "@/components/shared/AppHeader.vue";
import StaffReportsCard from "@/components/staff/widgets/StaffReportsCard.vue";

export default {
  name: "StaffReportsDashboard",
  components: {StaffReportsCard, AppHeader},

  data() {
    return {
      reportsDataLoaded: false,
      reportsData: {
        groupReports: [
          {
            hrefLink: "/staff/reports/group",
            headerText: "Group Summary",
            filename: "group-summary-sat.png",
            footerText: "Group composite scores, section scores, and strength and improvement areas of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/subsection-details",
            headerText: "Group Subsection Details",
            filename: "group-subsection-sat.png",
            footerText: "Group subsection details for each subject area of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/comparison",
            headerText: "Group Comparison",
            filename: "group-comparison-sat.png",
            footerText: "Group comparison of composite scores, section scores, and subsection details of two assessments",
          },
        ],
        individualReports: [
          {
            hrefLink: "/staff/reports/group/student-performance",
            headerText: "Individual Student Performance",
            filename: "individual-performance-sat.png",
            footerText: "Composite and section scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/individual/compare",
            headerText: "Individual Comparison",
            filename: "individual-comparison-sat.png",
            footerText: "Group Composite Scores, Section Scores, Strength and Improvement Areas",
          }
        ],
        exports: [
          {
            hrefLink: "/staff/reports/score-export",
            headerText: "Score Export",
            filename: "export-score-sat.png",
            footerText: "Export includes composite scores, section scores, and subsection scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/response-export",
            headerText: "Response Export",
            filename: "export-response-sat.png",
            footerText: "Export includes item level response and time spent per item for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/pdf-export",
            headerText: "PDF Student Reports",
            filename: "export-pdf-sat.png",
            footerText: "Export includes a printable PDF assessment score report for each individual student",
          },
        ],
      }
    }
  },

  mounted() {
    this.loadReportData()
    this.reportsDataLoaded = true
  },



  methods: {
    ...mapGettersUser(['userSchool']),

    loadReportData(){
      if(this.userSchool().courseType === 'SAT') {
        return
      }

      if(this.userSchool().courseType === 'ACT') {
        this.loadReportAct()
        return
      }

      this.loadReportActSat()
    },

    loadReportAct() {
      this.reportsData = {
        groupReports: [
          {
            hrefLink: "/staff/reports/group",
            headerText: "Group Summary",
            filename: "group-summary-act.png",
            footerText: "Group composite scores, section scores, and strength and improvement areas of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/subsection-details",
            headerText: "Group Subsection Details",
            filename: "group-subsection-act.png",
            footerText: "Group subsection details for each subject area of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/comparison",
            headerText: "Group Comparison",
            filename: "group-comparison-act.png",
            footerText: "Group comparison of composite scores, section scores, and subsection details of two assessments",
          },
        ],
        individualReports: [
          {
            hrefLink: "/staff/reports/group/student-performance",
            headerText: "Individual Student Performance",
            filename: "individual-performance-act.png",
            footerText: "Composite and section scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/individual/compare",
            headerText: "Individual Comparison",
            filename: "individual-comparison-act.png",
            footerText: "Group Composite Scores, Section Scores, Strength and Improvement Areas",
          }
        ],
        exports: [
          {
            hrefLink: "/staff/reports/score-export",
            headerText: "Score Export",
            filename: "export-score-act.png",
            footerText: "Export includes composite scores, section scores, and subsection scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/response-export",
            headerText: "Response Export",
            filename: "export-response-act.png",
            footerText: "Export includes item level response and time spent per item for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/pdf-export",
            headerText: "PDF Student Reports",
            filename: "export-pdf-act.png",
            footerText: "Export includes a printable PDF assessment score report for each individual student",
          },
        ],
      }
    },

    loadReportActSat() {
      this.reportsData = {
        groupReports: [
          {
            hrefLink: "/staff/reports/group",
            headerText: "Group Summary",
            filename: "group-summary-act.png",
            footerText: "Group composite scores, section scores, and strength and improvement areas of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/subsection-details",
            headerText: "Group Subsection Details",
            filename: "group-subsection-act.png",
            footerText: "Group subsection details for each subject area of an assessment",
          }
          ,{
            hrefLink: "/staff/reports/group/comparison",
            headerText: "Group Comparison",
            filename: "group-comparison-sat.png",
            footerText: "Group comparison of composite scores, section scores, and subsection details of two assessments",
          },
        ],
        individualReports: [
          {
            hrefLink: "/staff/reports/group/student-performance",
            headerText: "Individual Student Performance",
            filename: "individual-performance-act.png",
            footerText: "Composite and section scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/individual/compare",
            headerText: "Individual Comparison",
            filename: "individual-comparison-sat.png",
            footerText: "Group Composite Scores, Section Scores, Strength and Improvement Areas",
          }
        ],
        exports: [
          {
            hrefLink: "/staff/reports/score-export",
            headerText: "Score Export",
            filename: "export-score-sat.png",
            footerText: "Export includes composite scores, section scores, and subsection scores for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/response-export",
            headerText: "Response Export",
            filename: "export-response-act.png",
            footerText: "Export includes item level response and time spent per item for each individual student",
          }
          ,{
            hrefLink: "/staff/reports/pdf-export",
            headerText: "PDF Student Reports",
            filename: "export-pdf-act.png",
            footerText: "Export includes a printable PDF assessment score report for each individual student",
          },
        ],
      }
    },

    goToDownloads() {
      this.$router.push({name: "StaffPdfDownloads"})
    }
  }
}
</script>

<style scoped lang="scss">
.reports-main-header {
  min-height: 68px;
  display: flex;
  align-items: center;
  padding: 15px 16px;
  background: #38566B;

  h3 {
    color: #FFFFFF;
  }
}


.reports-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;

  .report-card {
    //height: 360px;
    max-width: 362px;
    min-width: 362px;
    box-shadow: 2px 2px 4.9px 0 #0000001A !important;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: 5px;
    text-decoration: none;


    &:hover {
      background-color: #fff !important;
      box-shadow: 0 1px 3px 3px rgba(187, 187, 187, .13) !important;
    }

    &__text-container {
      background: transparent;
      box-shadow: none !important;
      border: none;
      max-width: 270px;
      min-width: 270px;

      &:hover {
        background-color: transparent !important;;
        box-shadow: none !important;
      }
    }

    .report-card-header {
      border-radius: 4px 4px 0 0 !important;
      padding: 20px 24px;
      text-align: center;
      background: linear-gradient(127.02deg, #EAF4FF 14.35%, #DBE6F2 46.14%);

      h4 {
        font-size: 20px;
      }
    }

    .report-img-container {
      padding: 16px 24px;
    }

    .report-card-footer {
      border-radius: 0 0 4px 4px !important;
      padding: 20px 24px;
      background: #F7F7F7;
    }

  }
}

</style>
<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <dialog-delete-confirmation
      :message="deleteDialogConfirmationMessage"
      :active="dialogResetConfirmation"
      @confirmDeletion="onDeleteAssessment()"
    />

    <!-- Active Assessments -->
    <v-row
      class="mb-2 mt-0"
      align="center"
      :dense="true"
    >
      <v-col>
        <h3>Active Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(activeAssessments)"
      :headers="headers"
      :headers-length="activeAssessments.length + 1"
      :items="activeAssessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template #[`item.assessment.section`]="{ item }">
        {{ item.assessment.section }} ({{ item.assessment.duration }} Minutes)
      </template>

      <template #[`item.learnosityStatus`]="{ item }">
        <span
          class="mr-2 font-weight-500"
          :class="(itemStatus(item.learnosityStatus))"
        >
          {{ item.learnosityStatus.split("_").map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(" ") }}
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Active Assessments -->

    <!-- Upcoming Assessments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
      :dense="true"
    >
      <v-col
        align-self="end"
      >
        <h3>Upcoming Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(upcomingAssessments)"
      :headers="headers"
      :headers-length="upcomingAssessments.length + 1"
      :items="upcomingAssessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template #[`item.assessment.section`]="{ item }">
        {{ item.assessment.section }} ({{ item.assessment.duration }} Minutes)
      </template>

      <template #[`item.learnosityStatus`]="{ item }">
        <span
          class="mr-2 font-weight-500"
          :class="(itemStatus(item.learnosityStatus))"
        >
          {{ item.learnosityStatus.split("_").map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(" ") }}
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Upcoming Assessments -->

    <!-- Past Assessments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
      :dense="true"
    >
      <v-col
        align-self="end"
      >
        <h3>Past Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(pastAssessments)"
      :headers="headers"
      :headers-length="pastAssessments.length + 1"
      :items="pastAssessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template #[`item.assessment.section`]="{ item }">
        {{ item.assessment.section }} ({{ item.assessment.duration }} Minutes)
      </template>

      <template #[`item.learnosityStatus`]="{ item }">
        <span
          class="mr-2 font-weight-500"
          :class="(itemStatus(item.learnosityStatus))"
        >
          {{ item.learnosityStatus.split("_").map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(" ") }}
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Past Assessments -->

    <!-- Completed Assessments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
      :dense="true"
    >
      <v-col
        align-self="end"
      >
        <h3>Completed Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(completedAssessments)"
      :headers="headers"
      :headers-length="completedAssessments.length + 1"
      :items="completedAssessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template #[`item.assessment.section`]="{ item }">
        {{ item.assessment.section }} ({{ item.assessment.duration }} Minutes)
      </template>

      <template #[`item.learnosityStatus`]="{ item }">
        <span
          class="mr-2 font-weight-500"
          :class="(itemStatus(item.learnosityStatus))"
        >
          {{ item.learnosityStatus.split("_").map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(" ") }}
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canResetAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Reset assessment"
                @click.stop="handleAction('reset', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Completed Assessments -->
  </v-container>
</template>

<script type="text/javascript">
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
const {mapGetters: mapAssessmentGetters} = createNamespacedHelpers('assessments')

import {StaffStudentAssessmentApi, AdminSchoolApi, StaffUserApi} from '@/api'
import {StudentAssessment} from '@/models'

import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";

export default {
  components: {
    DialogDeleteConfirmation,
  },
  props: {
    classSpecific: Boolean,
    sectionId: {
      default: null,
      type: [Number, String],
      required: false
    },
    id: {
      type: [Number, String],
      default: null
    },
    studentId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      dialogAssessment: false,
      dialogResetConfirmation: false,
      dialogTitle: '',
      dialogType: '',
      deleteDialogConfirmationMessage: "Resetting the assessment will delete the student's responses for this exam from the server. The data cannot be recovered and this action cannot be undone.",
      assessmentProp: {},
      assessments: [],
      activeAssessments: [],
      upcomingAssessments: [],
      pastAssessments: [],
      completedAssessments: [],
      schoolId: null,
      school: {},
      primarySections: [],
      headers: [],
      tableHeight: '300px',
    }
  },

  computed: {
    ...mapUserState(["user"]),
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  mounted() {
    this.setup()
  },

  methods: {
    ...mapSectionGetters(["getPrimarySections"]),
    ...mapAssessmentGetters(["getAvailableAssessments", "getAvailableAssessmentTypes", "getAvailableAssessmentSets"]),

    async setup() {
      this.setHeaders()
      this.primarySections = this.getPrimarySections()
      this.loadAssessments()
    },

    setHeaders() {
      this.headers = [
        {
          text: 'Assessment Name',
          value: 'assessment.simpleAssessmentName',
          align: 'left',
          sortable: true
        },
        {
          text: 'Assessment Section',
          value: 'assessment.section',
          align: 'left',
          sortable: true
        },
        {
          text: 'Start Time',
          value: 'availabilityStart',
          align: 'left',
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'availabilityEnd',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'learnosityStatus',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false,
          width: 10,
        }
      ]
    },

    async getSchool() {
      let response = await AdminSchoolApi.get(this.$route.params.id)
      this.school = response.school
    },

    async loadAssessments() {
      const response = await StaffUserApi.getAssignedAssessments(this.studentId)
      this.assessments = response.studentAssessments
      const studentAssessments = response.studentAssessments.map(assessment => new StudentAssessment(assessment))

      this.activeAssessments = studentAssessments.filter((item) => item.status == "active")
      this.completedAssessments = studentAssessments.filter((item) => item.status == "completed")
      this.upcomingAssessments = studentAssessments.filter((item) => item.status == "upcoming")
      this.pastAssessments = studentAssessments.filter((item) => item.status == "past")
    },

    handleAction(action, assessment) {
      switch (action) {
        case 'reset':
          this.onResetDialog(assessment)
          break
        default:
          break
      }
    },

    onResetDialog(assessment) {
      this.assessmentProp = assessment
      this.dialogResetConfirmation = false
      this.$nextTick(() => {
        this.dialogResetConfirmation = true

      })
    },

    async onDeleteAssessment() {
      let assessment = this.assessmentProp
      try {
        this.tableLoading = true
        const response = await StaffStudentAssessmentApi.reset({
          sectionId: assessment.displayedAvailability.sectionId,
          assessmentId: assessment.id
        })
        this.addNotification('success', response.message)
        this.dialogResetConfirmation = false
        this.loadAssessments()
      } catch {
        this.addNotification('error', "Something went wrong. Please try again.")
      } finally {
        this.tableLoading = false
      }
    },

    onCloseDialogAssessments() {
      this.dialogAssessment = false
    },

    canResetAssessment(assessment) {
      if (assessment.learnosityStatus == "not_started" || assessment.learnosityStatus == "") {
        return false
      }

      if (this.user.role === "Horizon Admin" || this.user.role === "Teacher Admin") {
        return true
      } else if (this.user.role === 'Teacher' && this.user.school?.schoolAdminOnly) {
        return false
      } else {
        return this.primarySections.filter(s => assessment.displayedAvailability.sectionId == s.id).length > 0
      }
    },

    itemStatus(status) {
      if (status === 'completed') return 'text-success'
      if (status === 'in_progress') return 'text-warning'
    },

    formattedAssessmentName(item) {
      return `${item.assessment.assessmentType} ${item.assessment.assessmentSet}`
    },

    getTableHeight(array) {
      if (array.length < 6) return undefined

      return this.tableHeight
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="d-none d-sm-flex breadcrumb-container">
    <v-btn
      icon
      width="18"
      height="18"
      @click="toHome()"
    >
      <v-img
        class="cursor-pointer"
        contain
        :src="require('@/assets/icons/breadcrumb-home.png')"
        max-width="17.5"
        max-height="18"
        alt="Home icon in breadcrumbs"
      />
    </v-btn>
    <v-icon
      class="breadcrumb-home-divider"
      size="20"
      color="#f47e26"
    >
      mdi-chevron-right
    </v-icon>
    <v-breadcrumbs
      class="pa-0"
      large
      divider=">"
      :items="breadcrumbs"
    >
      <template #item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :disabled="!item.to"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template #divider>
        <v-icon
          color="#f47e26"
          size="20"
        >
          mdi-chevron-right
        </v-icon>
      </template>
    </v-breadcrumbs>
    <v-spacer />
    <div
      v-if="!isAdmin()"
      class="d-flex"
    >
      <v-img
        class="mr-2"
        contain
        :src="require('@/assets/icons/profile.png')"
        max-width="17.5"
        max-height="18"
        alt="profile-icon"
      />
      <span class="font-size-16 font-weight-500 color-primary">
        {{ userFullName }}
      </span>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters: mapGettersUser} = createNamespacedHelpers('user')
const {mapGetters: mapGettersConfig} = createNamespacedHelpers('app_config')

export default {
  name: "AppBreadCrumb",
  data() {
    return {}
  },

  computed: {
    ...mapGettersUser(["userFullName"]),
    breadcrumbs() {
      if (typeof this.$route.meta.breadcrumb === "function") {
        return this.$route.meta.breadcrumb.call(this, this.$route);
      }
      return this.$route.meta.breadcrumb;
    },
  },

  methods: {
    ...mapGettersUser(["userRole"]),
    ...mapGettersConfig(["dashboard"]),
    toHome() {
      if (!['Schools', 'StaffHome', 'StudentDashboard'].includes(this.$route.name)) {
        this.$router.push(this.dashboard())
      }
    },

    isAdmin() {
      return this.userRole() === 'Horizon Admin';
    }
  },
}
</script>

<style scoped>
.breadcrumb-container {
  padding: 18px 12px 12px 12px;
  margin-bottom: 14px;
  align-items: center;
}
</style>
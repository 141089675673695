<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      :nudge-width="50"
      offset-y
      transition="slide-y-transition"
      left
      fixed
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="indigo"
          icon
          v-bind="attrs"
          width="24"
          height="24"
          class="mr-1"
          aria-label="Open filter options"
          v-on="on"
        >
          <v-icon
            :color="activeFilters.length !== 0 && activeFilters.length !== filterOptions.length ? '#f4701f' : 'default'"
            size="16"
          >
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </template>
      <v-list
        dense
        class="pa-0"
      >
        <v-container
          v-if="filterOptions.length === 0"
          fluid
        >
          <v-row no-gutters>
            <v-col cols="12">
              <h6>No filters available</h6>
            </v-col>
          </v-row>
        </v-container>
        <div v-else>
          <v-list-item-group
            v-model="activeFilters"
            multiple
            class="py-2"
            @change="onFilterChange()"
          >
            <v-list-item
              v-for="(item, idx) in filterOptions"
              :key="idx"
              :ripple="false"
              :value="typeof item === 'object' && item !== null ? item.id: item"
              :disabled="loading"
              tabindex="0"
            >
              <template #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox
                    :input-value="active"
                    :true-value="item"
                    color="primary"
                    :ripple="false"
                    dense
                    :disabled="loading"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6>{{ typeof item === 'object' && item !== null ? item.name : item }}</h6>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                class="btn-table"
                text
                block
                color="#f4701f"
                :disabled="loading"
                @click="onClearAll()"
              >
                <h6 class="color-orange">
                  Clear all
                </h6>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: "AppTableFilter",

  props: {
    filterOptions: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    column: {
      type: String,
      required: true,
    },
    loading: Boolean
  },

  data() {
    return {
      activeFilters: [],
      menu: false
    }
  },

  methods: {
    onFilterChange() {
      if (this.loading) return
      this.$emit('onFilterChange', this.column, this.activeFilters)
    },

    onClearAll() {
      if (this.activeFilters.length === 0 || this.loading) return
      this.activeFilters = []
      this.$emit('onFilterChange', this.column, this.activeFilters)
    }
  }

}
</script>

<style scoped>

.v-list-item-group {
  max-height: 250px;
  overflow: auto
}

.v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
  padding: 0 12px;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: .5rem !important;
}

.v-list-item--link {
  transition: background-color .3s cubic-bezier(.25, .8, .5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, .13);
}

/* Scroll bar styling */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6a6a6a;
}

</style>
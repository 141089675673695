<template>
  <div>
    <app-tabs-secondary
      :tabs="tabs"
      @onTabChange="onLoadCategory(...arguments)"
    />
    <v-container fluid>
      <v-row
        align="center"
        dense
        class="title-min-58"
      >
        <v-col
          cols="12"
          sm="auto"
        >
          <h3>
            {{ tabTypePlural }}
          </h3>
        </v-col>
        <v-spacer />
        <v-col
          v-if="tabSelected === 0"
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__primary"
            :loading="tableLoading"
            block
            @click="onCreateTerm()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/add-1.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Create Term
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>
            <v-row
              dense
              class="table-header-extension"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="tableSearch"
                  placeholder="Search"
                  prepend-inner-icon="mdi-magnify"
                  class="input-default input-default__table"
                  clearable
                  hide-details
                  solo
                  flat
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  v-if="tabSelected === 0"
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onArchiveTerms()"
                >
                  Archive Terms
                </v-btn>
                <v-btn
                  v-else
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onActivateTerms()"
                >
                  Activate Terms
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onShowDeleteMultipleDialog()"
                >
                  Delete Terms
                </v-btn>
              </v-col>
              <v-col
                v-if="showErrorMessage"
                cols="12"
              >
                <h6 class="color-danger text-right">
                  {{ errorTitle }}
                </h6>
              </v-col>
            </v-row>

            <v-data-table
              v-model="selectedRows"
              :height="getTableHeight()"
              show-select
              item-key="id"
              :server-items-length="totalRows"
              :options.sync="tableOptions"
              :headers="headers"
              :headers-length="headers.length + 1"
              :items="termsList"
              :loading="tableLoading ? '#3d5d74' : false"
              class="table-default table-default__select table-radius-top-none table-default__header-fixed"
              fixed-header
              :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions:[itemsPerPage],
                disableItemsPerPage: true,
              }"
              @toggle-select-all="onToggleSelectAll"
            >
              <template #[`footer.page-text`]="items">
                <span class="font-weight-700">{{ items.pageStart }}</span> to
                <span class="font-weight-700">{{ items.pageStop }}</span> of
                <span class="font-weight-700">{{ items.itemsLength }}</span>
              </template>
              
              <template
                v-for="header in headers"
                #[`header.${header.value}`]
              >
                <span
                  :key="header.text"
                  :aria-label="`Header: ${header.text}`"
                  tabindex="0"
                >
                  {{ header.text }}
                </span>
              </template>

              <template
                v-if="!isMobile"
                #[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  v-if="!isTermDefault(item.source)"
                  :value="isSelected"
                  :readonly="item.hiddenAccount"
                  :disabled="item.hiddenAccount"
                  @input="select($event)"
                />
              </template>

              <template
                v-if="!isMobile"
                #[`item.startDate`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.startDate) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.endDate`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.endDate) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.actions`]="{ item }"
              >
                <td>
                  <v-row dense>
                    <v-col
                      class="d-flex justify-start"
                    >
                      <v-btn
                        v-if="!isTermDefault(item.source)"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Edit term"
                        @click="onEditDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!isTermNotDeletable(item.source)"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Delete term"
                        @click="onDeleteDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template
                v-if="isMobile"
                #item="{ item, isSelected, select }"
              >
                <tr>
                  <td>
                    <ul class="flex-content">
                      <li
                        v-if="!isTermDefault(item.source)"
                        class="flex-item"
                        data-label="Selected"
                      >
                        <v-simple-checkbox
                          :value="isSelected"
                          @click="select(!isSelected)"
                        />
                      </li>
                      <li
                        class="flex-item"
                        data-label="Class Name"
                      >
                        {{ item.name }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Start Date"
                      >
                        {{ formatDateTable(item.startDate) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="End Date"
                      >
                        {{ formatDateTable(item.endDate) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="School Year"
                      >
                        {{ item.schoolYear?.shortName }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Created By"
                      >
                        {{ item.source }}
                      </li>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-btn
                          v-if="!isTermDefault(item.source)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Edit term"
                          @click="onEditDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-square-edit-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!isTermNotDeletable(item.source)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Delete term"
                          @click="onDeleteDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </ul>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-term-form
      :active="dialogCreateTerm"
      :title="dialogTitle"
      :term-prop="termProp"
      :type="dialogType"
      :school-id="id"
      @closeDialog="onCloseTermDialog()"
      @closeDialogUpdate="onCloseTermDialog(true)"
    />

    <dialog-action
      :active="dialogArchiveTerm"
      :loading="actionLoader"
      body-text-prop="Do you want to archive the selected terms? Archiving these terms will also archive all classes associated with these terms."
      @closeDialog="onCloseDialogArchiveTerm()"
      @confirmDialog="onConfirmStatusChange(true)"
    />

    <dialog-action
      :active="dialogActivateTerm"
      :loading="actionLoader"
      body-text-prop="Do you want to activate the selected terms? Activating these terms will also activate all classes associated with these terms."
      @closeDialog="onCloseDialogActivateTerm()"
      @confirmDialog="onConfirmStatusChange(false)"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveTerm()"
    />

    <dialog-delete-confirmation
      :active="dialogBulkDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveMultiple()"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters} = createNamespacedHelpers('user')
import moment from 'moment';
import debounce from 'lodash/debounce'
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAction from "@/components/shared/DialogAction.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import {AdminSchoolApi, StaffSchoolApi} from "@/api";
import DialogTermForm from "@/components/shared/DialogTermForm.vue";
import functions from "@/api/shared/functions";

export default {
  name: "AdminTerms",
  components: {DialogTermForm, AppTabsSecondary, DialogDeleteConfirmation, DialogAction},

  props: {
    id: {
      type: [Number, String],
      default: null
    },
    school: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      pageLoadedOnce: false,
      tableLoading: true,
      actionLoader: false,
      dialogDeleteConfirmation: false,
      dialogBulkDeleteConfirmation: false,
      deletionLoader: false,
      dialogCreateTerm: false,
      dialogArchiveTerm: false,
      dialogActivateTerm: false,
      dialogTitle: '',
      dialogType: 'new',
      tabSelected: 0,
      selectedRows: [],
      showErrorMessage: false,
      errorTitle: 'Please select at least one term!',
      tableSearch: '',
      termsList: [],
      tableOptions: {},
      itemsPerPage: 100,
      totalRows: 0,
      tableHeight: 'calc(90vh - 544px)',
      termProp: {},
      disabledCount: 0,
      tabs: [
        {
          tabText: 'Active Terms',
          text: 'Active Terms'
        },
        {
          tabText: 'Archived Terms',
          text: 'Archived Terms'
        },
      ],
      headers: [
        {
          text: 'Term Name',
          value: 'name',
          align: 'left',
          sortable: true,
          sortBy: 'name',
        },
        {
          text: 'Start Date',
          value: 'startDate',
          align: 'left',
          sortable: true,
        },
        {
          text: 'End Date',
          value: 'endDate',
          align: 'left',
          sortable: true,
        },
        {
          text: 'School Year',
          value: 'schoolYear.shortName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Created By',
          value: 'source',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 100
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    tabTypePlural() {
      return this.tabs[this.tabSelected].tabText
    },

    tabTypeSingular() {
      return this.tabs[this.tabSelected].text
    }
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.getTerms()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.getTerms()
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapGetters(['userRole']),

    onLoadCategory(tab) {
      this.tabSelected = tab
      this.getTerms()
      this.resetSelected()
    },

    isTermDefault(termSource) {
      return termSource === 'default'
    },

    isTermNotDeletable(termSource) {
      return termSource !== 'user_created'
    },

    onToggleSelectAll(props) {
      if (this.selectedRows.length !== this.termsList.length - this.disabledCount) {
        this.resetSelected()
        props.items.forEach(item => {
          if (!this.isTermDefault(item.source)) {
            this.selectedRows.push(item)
          }
        });
      } else this.resetSelected()
    },

    async getTerms() {
      try {
        this.disabledCount = 0
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        const isActive = this.tabSelected === 0
        let order = 'asc'
        let sort = 'name'
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        if (sortBy[0]) {
          sort = sortBy[0]
          if (sort === 'schoolYear.shortName') sort = 'schoolYearId'
          order = sortDesc[0] ? 'desc' : 'asc'
        }

        const api = this.userRole() === 'Horizon Admin' ? AdminSchoolApi : StaffSchoolApi

        const response = await api.getSchoolTerms(this.id, page, this.itemsPerPage, sort, order, searchText, !isActive)
        this.totalRows = response?.meta?.totalCount
        this.termsList = response.terms
        this.termsList.forEach(item => {
          if (this.isTermDefault(item.source)) this.disabledCount += 1
        })
      } catch {
        this.addNotification('error', 'There was a problem getting terms')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    // Create term methods
    onCreateTerm() {
      this.dialogTitle = `Create Term`
      this.dialogType = 'new'
      this.dialogCreateTerm = true
    },

    // Edit term methods
    onEditDialog(term) {
      this.termProp = functions.deepCopySync(term)
      this.dialogTitle = `Edit Term`
      this.dialogType = 'edit'
      this.dialogCreateTerm = true
    },

    onCloseTermDialog(updateTable) {
      if (updateTable) {
        this.getTerms()
        this.resetSelected()
      }
      this.dialogCreateTerm = false
    },

    formatDateTable(date) {
      return moment.utc(date).format('LL')
    },

    resetSelected() {
      this.selectedRows = []
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    onDeleteDialog(termProp) {
      this.termProp = termProp
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    async onRemoveTerm() {
      try {
        this.deletionLoader = true
        const api = (this.userRole() === 'Horizon Admin') ? AdminSchoolApi : StaffSchoolApi
        await api.deleteTerm({
          schoolId: this.id,
          termId: this.termProp.id
        })
        this.dialogDeleteConfirmation = false
        this.getTerms()
        this.resetSelected()
        this.addNotification('success', 'Term removed successfully')
      } catch {
        this.addNotification('error', 'Failed to remove term. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onShowDeleteMultipleDialog() {
      if (this.selectedRows.length === 0) {
        this.noRowsSelectedMessage()
        return
      }

      let hasNotDeletableTerm = false
      this.selectedRows.forEach(item => {
        if (this.isTermNotDeletable(item.source)) {
          hasNotDeletableTerm = true
        }
      })

      if (hasNotDeletableTerm) {
        this.hasNotDeletableTerms()
        return
      }

      this.showErrorMessage = false
      this.dialogBulkDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogBulkDeleteConfirmation = true
      })
    },

    async onRemoveMultiple() {
      try {
        this.deletionLoader = true
        const idList = this.selectedRows.map(obj => obj.id)
        const api = (this.userRole() === 'Horizon Admin') ? AdminSchoolApi : StaffSchoolApi

        await api.bulkDeleteTerms({
          schoolId: this.id,
          idArray: idList
        })
        this.dialogBulkDeleteConfirmation = false
        this.getTerms()
        this.resetPage()
        this.resetSelected()
        this.addNotification('success', 'Terms removed successfully')
      } catch {
        this.addNotification('error', 'Failed to remove the selected terms. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
    },

    // Archive/Activate term methods
    onArchiveTerms() {
      if (this.selectedRows.length === 0) {
        this.noRowsSelectedMessage()
        return
      }
      this.dialogArchiveTerm = true
    },

    onActivateTerms() {
      if (this.selectedRows.length === 0) {
        this.noRowsSelectedMessage()
        return
      }
      this.dialogActivateTerm = true
    },

    async onConfirmStatusChange(status) {
      try {
        this.actionLoader = true
        const idList = this.selectedRows.map(obj => obj.id)
        const api = (this.userRole() === 'Horizon Admin') ? AdminSchoolApi : StaffSchoolApi
        await api.statusChangeTermsBulk({
          schoolId: this.id,
          idArray: idList,
          status: status
        })
        this.dialogArchiveTerm = false
        this.dialogActivateTerm = false
        this.getTerms()
        this.resetSelected()
        if (status === false) {
          this.addNotification('success', 'Terms activation job has been queued')
        } else {
          this.addNotification('success', 'Terms archiving job has been queued')
        }
      } catch {
        if (status === false) {
          this.addNotification('error', 'Failed to start terms activation job. A job may already be running.')
        } else {
          this.addNotification('error', 'Failed to start terms archiving job. A job may already be running.')
        }
      } finally {
        this.actionLoader = false
      }
    },

    onCloseDialogArchiveTerm() {
      this.dialogArchiveTerm = false
    },

    onCloseDialogActivateTerm() {
      this.dialogActivateTerm = false
    },

    noRowsSelectedMessage() {
      this.errorTitle = 'Please select at least one term!'
      this.showErrorMessage = true
      setTimeout(() => {
        this.showErrorMessage = false
      }, 5000)
    },

    hasNotDeletableTerms() {
      this.errorTitle = 'Some selected terms are undeletable(clever/classlink)!'
      this.showErrorMessage = true
      setTimeout(() => {
        this.showErrorMessage = false
      }, 5000)
    },

    getTableHeight() {
      if (this.termsList?.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  },

}
</script>

<style scoped>

</style>

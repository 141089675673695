import HorizonApi from '@/api/horizon_api'
const DISTRICT_ADMIN_STUDENT_RESULTS_PATH = '/api/district_admin/student_results'

export default {
  getOverview ({ assessmentSetId, assessmentTypeId, schoolYearId } = {}) {
    const params = {
      params: {
        assessment_set_id: assessmentSetId,
        assessment_type_id: assessmentTypeId,
        school_year_id: schoolYearId
      } 
    }

    return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}`, params)
  },

  learnosityResponseAnalysisByItem ({ assessmentId, schoolIds, gradeLevels, schoolYear, page, query } = {}) {
    let params = {
      params: {
        assessment_id: assessmentId,
        school_year_id : schoolYear,
        school_ids: schoolIds,
        grade_levels: gradeLevels,
        page: page,
        query: query
      }
    }

    return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}/learnosity_response_analysis_by_item`, params)
  },

  getReportsSummary({assessmentTypeId, assessmentSetId, schoolIds, gradeLevels, schoolYear } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
        school_ids : schoolIds,
        grade_levels: gradeLevels,
        school_year_id : schoolYear
      }
    }
    return HorizonApi.get(DISTRICT_ADMIN_STUDENT_RESULTS_PATH, params)
  },

  getStrengthAndImprovements({ assessmentId, gradeLevels, schoolYear, schoolIds }) {
    let params = {
      params: {
        assessment_id: assessmentId,
        grade_levels: gradeLevels,
        school_year_id : schoolYear,
        school_ids : schoolIds,
      }
    }
     return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}/strength_and_improvements`, params)
  },

  getReportsSubsectionCategoryTabs ({ assessmentSetId, assessmentTypeId } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
      }
    }
    return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}/categories`, params)
  },

  getReportsSubsectionCategoryDetails ({ assessmentId, gradeLevels, schoolYear, schoolIds } = {}) {
    const params = {
      params: {
        assessment_id: assessmentId ,
        grade_levels: gradeLevels,
        school_year_id: schoolYear,
        school_ids: schoolIds,
      }
    }
    return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}/category_details`, params)
  },

  getReportsSubsectionTopicAreaBreakdown({ assessmentId, topicAreaId, gradeLevels, schoolYear, schoolIds }) {
    let params = {
      params: {
        assessment_id: assessmentId,
        topic_area_id: topicAreaId,
        grade_levels: gradeLevels,
        school_year_id : schoolYear,
        school_ids : schoolIds
      }
    }
     return HorizonApi.get(`${DISTRICT_ADMIN_STUDENT_RESULTS_PATH}/topic_area_breakdown`, params)
  },
}